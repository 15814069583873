<script setup lang="ts">
import {
	mapBrandsToLinkNodes,
	mapDropsToLinkNodes,
	type BrandsResponse,
	type DropsResponse,
	type EntityData,
	type LinkNode,
} from '@laam/cms-shared';
import EntityCard from './EntityCard.vue';
import { computed, inject, ref, type Ref } from 'vue';
import Button from '../base/button/Button.vue';
import Collapsible from '../base/collapsible/Collapsible.vue';
import CollapsibleContent from '../base/collapsible/CollapsibleContent.vue';
import CollapsibleTrigger from '../base/collapsible/CollapsibleTrigger.vue';
import Nov11SaleIcon from '../../icons/Nov11SaleIcon.vue';

interface Props {
	metadata: EntityData;
	data: DropsResponse | BrandsResponse;
	sectionIndex: number;
}

const props = defineProps<Props>();

const storeType = inject('storeType') as Ref<string>;

if (!Array.isArray(props.data)) {
	console.log('EntityGrid: data is not an array', {
		...props,
	});
}

const entities = computed<LinkNode[]>(() => {
	if (props.metadata.entity === 'brands') {
		return mapBrandsToLinkNodes(props.data as BrandsResponse);
	} else if (props.metadata.entity === 'drops') {
		return mapDropsToLinkNodes(props.data as DropsResponse, storeType.value);
	} else {
		throw new Error('Unsupported entity');
	}
});

const isOpen = ref(false);

const title = ref<HTMLSpanElement | null>(null);

const deviceType = inject('deviceType') as Ref<string | undefined>;

const itemsToSlice = computed(() => {
	if (deviceType.value === 'mobile') {
		return 8;
	} else {
		return 8;
	}
});

const handleClick = () => {
	if (!isOpen.value) {
		if (!title.value) {
			throw new Error('title ref is null');
		}
		title.value.scrollIntoView();
	}
};
const showNov11Sale = inject('showNov11Sale') as boolean;
</script>

<template>
	<div class="gap-3xl lg:gap-7xl px-3xl lg:px-0 flex flex-col items-center">
		<div
			class="gap-sm flex flex-col w-full"
			:class="{
				'items-center': metadata.alignment === 'center',
				'items-start': metadata.alignment === 'left',
			}"
		>
			<h2 ref="title" class="text-lg font-semibold lg:display-xs text-gray-900">
				{{ metadata.title }}
			</h2>

			<div v-if="showNov11Sale" class="flex gap-sm">
				<Nov11SaleIcon />
				<h1 class="text-sm font-normal text-gray-600">Exclusive</h1>
			</div>
			<h3
				v-else-if="metadata.subtitle"
				class="text-sm font-normal text-gray-900"
			>
				{{ metadata.subtitle }}
			</h3>
		</div>

		<Collapsible v-model:open="isOpen">
			<div class="gap-3xl lg:gap-7xl lg:grid-cols-4 grid grid-cols-2">
				<div
					v-for="(entity, horizontalIndex) in entities.slice(0, itemsToSlice)"
					:key="horizontalIndex"
				>
					<slot
						:entity="metadata.entity"
						:data="entity"
						:horizontal-index="horizontalIndex"
					>
						<EntityCard
							:entity="metadata.entity"
							:data="entity"
							:index="horizontalIndex"
						/>
					</slot>
				</div>
			</div>

			<CollapsibleContent
				class="gap-3xl lg:gap-7xl mt-7xl lg:grid-cols-4 grid grid-cols-2"
			>
				<div
					v-for="(entity, horizontalIndex) in entities.slice(itemsToSlice, 20)"
					:key="horizontalIndex"
				>
					<slot
						:entity="metadata.entity"
						:data="entity"
						:horizontal-index="horizontalIndex"
					>
						<EntityCard
							:entity="metadata.entity"
							:data="entity"
							:index="horizontalIndex"
						/>
					</slot>
				</div>
			</CollapsibleContent>

			<CollapsibleTrigger
				v-if="entities.length > itemsToSlice"
				as="div"
				class="lg:w-fit flex justify-center w-full mx-auto mt-3xl lg:!mt-7xl"
				@click="handleClick"
			>
				<Button variant="secondary" size="md" class="!py-md w-full !bg-white">
					{{ isOpen ? 'View Less' : 'View More' }}
				</Button>
			</CollapsibleTrigger>
		</Collapsible>
	</div>
</template>
