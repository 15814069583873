<script lang="ts" setup>
import type { LinkNode } from '@laam/cms-shared';
import { LazyImage } from '../lazy-image';
import { inject, type Ref } from 'vue';

interface EntityCardProps {
	data: LinkNode;
	entity: string;
}

defineProps<EntityCardProps>();

const showNov11Sale = inject('showNov11Sale') as boolean;
const storeType = inject('storeType') as Ref<string>;
</script>
<template>
	<div
		class="rounded-xs image-box w-full !min-h-[100px] lg:!min-h-[250px] relative aspect-[0.71] bg-gray-100 overflow-clip"
	>
		<LazyImage
			v-if="data?.image"
			:src="data?.image"
			:alt="data.name"
			width="280"
			provider="shopify"
			class="rounded-small w-full"
		/>
		<span
			v-if="data.max_sale"
			class="px-sm py-xxs lg:py-xs lg:px-lg left-sm lg:left-2xl lg:bottom-2xl bottom-sm lg:rounded-medium rounded-small bg-red-50 lg:text-sm box-border absolute block text-xs font-medium text-red-700 border border-red-200"
			:class="{
				'!border-0 !text-white !bg-error-800': showNov11Sale,
			}"
		>
			Upto {{ (data.max_sale * 100).toFixed(0) }}% off
		</span>
	</div>

	<div class="text-ellipsis gap-xs flex flex-col">
		<h3
			class="mt-md max-w-full text-sm font-medium text-gray-900 whitespace-pre-wrap text-left"
		>
			{{ data.name.replace(/__/g, '') }}
		</h3>
		<h4
			v-if="storeType === 'MARKETPLACE'"
			class="lg:text-sm text-xs text-gray-600 text-left"
		>
			{{ data.subtitle ?? '' }}
		</h4>
	</div>
</template>
