<script setup lang="ts">
import {
	type BrandsResponse,
	type DropsResponse,
	type EntityData,
	type LinkNode,
	mapBrandsToLinkNodes,
	mapDropsToLinkNodes,
} from '@laam/cms-shared';
import EntityCard from './EntityCard.vue';
import { computed, inject, ref, type Ref } from 'vue';
import Nov11SaleIcon from '../../icons/Nov11SaleIcon.vue';

interface Props {
	metadata: EntityData;
	data: DropsResponse | BrandsResponse;
	sectionIndex: number;
}

const storeType = inject('storeType') as Ref<string>;

const props = defineProps<Props>();

if (!Array.isArray(props.data)) {
	console.log('EntityCarousel: data is not an array', {
		...props,
	});
}

const entities = computed<LinkNode[]>(() => {
	if (props.metadata.entity === 'brands') {
		return mapBrandsToLinkNodes(props.data as BrandsResponse);
	} else if (props.metadata.entity === 'drops') {
		return mapDropsToLinkNodes(props.data as DropsResponse, storeType.value);
	} else {
		throw new Error('Unsupported entity');
	}
});

const deviceType = inject('deviceType') as Ref<string | undefined>;
const showNov11Sale = inject('showNov11Sale') as boolean;

const dropsCarouselRef = ref();
</script>

<template>
	<div
		class="entity-carousel gap-3xl lg:gap-7xl lg:px-0 px-3xl flex flex-col items-center"
	>
		<div
			class="gap-sm flex flex-col w-full"
			:class="{
				'items-center': metadata.alignment === 'center',
				'items-start': metadata.alignment === 'left',
			}"
		>
			<h2 ref="title" class="text-lg font-semibold lg:display-xs text-gray-900">
				{{ metadata.title }}
			</h2>

			<div v-if="showNov11Sale" class="flex gap-sm">
				<Nov11SaleIcon />
				<h1 class="text-sm font-normal text-gray-600">Exclusive</h1>
			</div>
			<h3 v-if="metadata.subtitle" class="text-sm font-normal text-gray-900">
				{{ metadata.subtitle }}
			</h3>
		</div>

		<Carousel
			ref="dropsCarouselRef"
			class="cms--carousel-entity-base w-full"
			:items-to-show="deviceType === 'mobile' ? 2.2 : 4"
			snap-align="start"
		>
			<Slide
				v-for="(card, horizontalIndex) in entities"
				:key="horizontalIndex"
				:index="horizontalIndex"
			>
				<slot
					:entity="metadata.entity"
					:data="card"
					:horizontal-index="horizontalIndex"
				>
					<EntityCard
						:entity="metadata.entity"
						:data="card"
						:index="horizontalIndex"
					/>
				</slot>
			</Slide>

			<template #addons>
				<Navigation v-if="entities.length > 4 && deviceType !== 'mobile'" />
			</template>
		</Carousel>
	</div>
</template>

<style>
.cms--carousel-entity-base .carousel__prev {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:mx-5xl;
}

.cms--carousel-entity-base .carousel__next {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:ml-5xl lg:mr-9xl;
}

.cms--carousel-entity-base .cms--carousel-drops__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--carousel-entity-base .carousel__slide {
	@apply pr-3xl lg:pr-7xl items-start;
}

.cms--carousel-entity-base .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--carousel-entity-base .carousel__pagination-item {
	@apply box-border;
}

.cms--carousel-entity-base .carousel__pagination-button {
	@apply p-none;
}

.cms--carousel-entity-base .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--carousel-entity-base .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
